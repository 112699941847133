<script lang="ts" setup>
const props = defineProps({
  title: String,
});
</script>

<template>
  <div class="title">{{ props.title }}</div>
</template>

<style lang="scss" scoped>
.title {
  font-size: 21px;
  color: rgba(#000, 0.8);
  line-height: 1.5;
  @include max-media(480) {
    font-size: 15px;
  }
}
</style>
